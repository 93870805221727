.team-bullet-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.team-bullet-center {
  display: flex;
  align-content: center;
  justify-content: center;
}
.team-bullet-container-founders {
  display: flex;
  flex-direction: row;
}

.team-bullet {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 6500px;
  padding: 28px;
}

.team-bullet-center {
  max-width: 680px;
}

.team-img {
  margin: 9px;
  width: 100px;
}

h4 {
  color: white;
  margin-bottom: 12px;
}

h2 {
  margin-bottom: 32px;
}

@media screen and (max-width: 768px) {
  .team-bullet-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .team-bullet {
    padding: 18px 0 0;
  }
}
