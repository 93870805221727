.brand-carousel-container {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
}

.brand-carousel-container div {
  flex: 1;
  max-width: 120px;
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-img {
  width: 100px;
}

@media screen and (max-width: 800px) {
  .brand-carousel-container {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
}
