.splash-container {
  padding: 32px;
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.splash-copy {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
}

.splash-copy h1 {
  line-height: 75px;
  letter-spacing: -0.04em;
}

.splash-copy h2 {
  letter-spacing: -0.04em;
  margin: 0.5rem 0 1rem;
}

.splash-copy p {
  line-height: 28px;
  margin-top: 1.5rem;
}

.svg-container {
  flex: 1;
  display: flex;
  justify-content: right;
  align-items: center;
}

video {
  padding: 12px;
  max-width: 550px;
}

@media screen and (max-width: 1050px) {
  .splash-container {
    flex-direction: column;
  }

  .splash-copy {
    margin: 0 0 3rem;
  }
  .svg-container {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .splash-copy h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .splash-copy p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .splash-copy h1 {
    font-size: 32px;
    line-height: 48px;
  }

  .splash-copy p {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  video {
    max-width: 300px;
  }
}
