* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.App-link {
  color: #61dafb;
}

.App-view {
  padding-right: 12px;
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.gradient__bg {
  background: -moz-radial-gradient(
    circle at 3% 25%,
    #002ffb 0%,
    rgba(4, 12, 24, 1) 10%
  );
  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 3% 25%,
    #002ffb 0%,
    rgba(4, 12, 24, 1) 25%
  );
  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 3% 25%,
    #002ffb 0%,
    rgba(4, 12, 24, 1) 25%
  );
  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 3% 25%,
    #002ffb 0%,
    rgba(4, 12, 24, 1) 25%
  );
  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 3% 25%,
    #002ffb 0%,
    rgba(4, 12, 24, 1) 25%
  );
}

.feature-img {
  margin: 48px;
  max-width: 450px;
}

@media screen and (max-width: 768px) {
  .feature-img {
    max-width: 220px;
  }
}

.container {
  padding-left: 6rem;
  padding-right: 6rem;
}

h1 {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 52px;
  line-height: 75px;
  letter-spacing: -0.04em;
  color: var(--color-text);
}

h2 {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 32px;
  color: var(--color-text);
}
h3 {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 26px;
  color: var(--color-text);
}
p {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 16px;
  color: var(--color-text);
}

.viewport-container {
  max-width: 1320px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
