.about-bullet-container {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.about-bullet {
  padding-top: 28px;
}
.about-bullet div {
  width: 38px;
  height: 3px;
  background: var(--gradient-bar);
  margin-bottom: 0.25rem;
}

@media screen and (max-width: 768px) {
  .about-bullet-container {
    flex-direction: column;
  }
  .about-bullet {
    padding: 18px 0 0;
  }
}
