@import url("https://use.typekit.net/skb0xxr.css");
:root {
  --font-family: "objektiv-mk1", sans-serif;
  --gradient-text: linear-gradient(89.97deg, #274bce 1.84%, #5f79f1 102.67%);
  --gradient-bar: linear-gradient(89.97deg, #274bce 1.84%, #5f79f1 102.67%);
  --color-bg: black;
  --color-text: #e5e7eb;
}

.section-container {
  padding: 22px;
  max-width: 1320px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.section-heading {
  margin-bottom: 32px;
}

body {
  font-family: var(--font-family);
}
