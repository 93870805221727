.research-inner-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.research-img-container {
  display: flex;
  align-items: center;
}

.research-bullet-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
}

.research-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8rem;
}

.research-heading-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.research-bullet {
  padding-top: 28px;
}

.research-bullet h3 {
  margin-bottom: 8px;
}

.research-bullet div {
  width: 38px;
  height: 3px;
  background: var(--gradient-bar);
  margin-bottom: 0.25rem;
}

@media screen and (max-width: 768px) {
  .research-bullet-container {
    display: flex;
    flex-direction: column;
  }
  .research-bullets {
    display: flex;
    flex-direction: column;
  }

  .tech-img {
    max-width: 350px;
  }

  .research-bullet {
    padding: 18px 0 0;
  }
}
