.navbar {
  max-width: 1320px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: white;
  padding-top: 22px;
  padding-bottom: 52px;
}

.nav-item {
  padding: 10px 20px;
  cursor: pointer;
}

.altus-nav-logo {
  max-width: 50px;
  margin: 5px;
}

.mobile-altus-navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mobile-altus-navbar-links-container p,
.mobile-altus-navbar-sign p,
.mobile-navbar-menu-container p {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  margin: 0 1rem;
  cursor: pointer;
}

.mobile-altus-navbar-sign button,
.mobile-navbar-menu-container button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #ff4820;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.mobile-altus-navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.mobile-altus-navbar-menu svg {
  cursor: pointer;
}

.mobile-navbar-menu-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: white;
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.mobile-navbar-menu-container p {
  margin: 1rem 0;
  color: black;
}

.nav-item {
  padding: 10px 20px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .nav-item {
    display: none;
  }
  .altus-navbar {
    display: none;
  }
  .mobile-altus-navbar-links-container {
    display: none;
  }
  .altus-navbar {
    justify-content: space-between;
  }

  .mobile-altus-navbar-menu {
    display: flex;
  }
}
