small {
  color: #d8dade;
}

.footer-link {
  cursor: pointer;
}

.altus-footer-logo {
  max-width: 1320px;
  width: 100%;
}

.nav-item {
  padding: 10px 20px;
  cursor: pointer;
  color: #e5e7eb !important;
}

.altus-footer-logo {
  max-width: 100px;
  padding-top: 8px;
  margin: 12px;
}

.footer {
  margin-top: 38px;
  max-width: 1320px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  border-top: 1px solid white;
  gap: 12px;
  padding-bottom: 12px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 9999;
}

.modal-window {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -50%);
  z-index: 10000;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
}
.modal-window-inner {
  width: 100%;
  border-radius: 0.125rem;
  color: white;
}

.btn-close {
  cursor: pointer;
  color: #ff002b;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  .footer {
    display: flex;
    flex-direction: column;
    color: #d8dade;
  }
  .research-bullets {
    display: flex;
    flex-direction: column;
  }

  .tech-img {
    max-width: 350px;
  }
}
