.vcStudio-bullet-container {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.section-copy {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vc-section-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vcStudio-img {
  padding: 12px;
  max-width: 450px;
}

.vc-studio-bullet div {
  width: 38px;
  height: 3px;
  background: var(--gradient-bar);
  margin-bottom: 0.75rem;
}

@media screen and (max-width: 900px) {
  .vc-section-row {
    flex-direction: column;
  }
}
